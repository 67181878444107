import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import { pipe, groupBy, reverse, find, sortBy } from 'ramda'
import { eachMonthOfInterval, format } from 'date-fns'

import LayoutFullWidth from '../../components/Layout/LayoutFullWidth'
import headerProps from '../../components/propTypes/headerProps'
import {
  Divider,
  Breadcrumbs,
  Typography,
  TransitionLink,
  Tabs,
} from '../../components'
import styles from './PressOfficeTemplate.module.css'
import Tab from '../../components/elements/Tabs/Tab'
import TabList from '../../components/elements/Tabs/TabList'
import TabPanel from '../../components/elements/Tabs/TabPanel'
import { flattenNodes } from '../../utils'

const groupByYear = pipe(
  groupBy(release => format(new Date(release.publishedDate), 'yyyy')),
)

const PressOfficePage = ({ data }) => {
  const {
    page: {
      frontmatter: { header },
    },
    pressReleases,
  } = data

  const releases = flattenNodes(pressReleases.edges)
  const releasesByYear = groupByYear(releases)

  return (
    <LayoutFullWidth header={header}>
      <div className="page-wrapper container">
        <Breadcrumbs>
          <Breadcrumbs.Item>
            <TransitionLink to="/about-us">About Us</TransitionLink>
          </Breadcrumbs.Item>
          <Breadcrumbs.Item>
            <span>Press Office</span>
          </Breadcrumbs.Item>
        </Breadcrumbs>

        <Divider thickness={4} />
        <Typography tag="h2" type="boxHeading" className="mb-4">
          Press Office
        </Typography>
        <Typography tag="h3" type="h2" className="mb-8">
          Press Releases, Op Eds, Radio and TV Clips
        </Typography>

        <div className={styles.list}>
          {reverse(Object.keys(releasesByYear)).map(year => {
            const releasesByMonth = groupBy(release =>
              format(new Date(release.publishedDate), 'MMM'),
            )(releasesByYear[year])

            const months = eachMonthOfInterval({
              start: new Date(2019, 12, 1),
              end: new Date(2020, 11, 1),
            })

            const firstMonth = find(month => {
              const formattedDate = format(new Date(month), 'MMM')
              return releasesByMonth[formattedDate] !== undefined
            })(months)

            const releasesSortedByDate = sortBy(release =>
              format(new Date(release.publishedDate), 'dd'),
            )

            return (
              <div key={year} className={styles.yearGroup}>
                <Tabs initialValue={format(new Date(firstMonth), 'MMM')}>
                  <div className={styles.tabsHeader}>
                    <Typography
                      type="boxHeading"
                      tag="strong"
                      className={styles.yearLabel}
                    >
                      {year}
                    </Typography>

                    <TabList className={styles.tabList}>
                      {months.map(month => {
                        const formattedMonth = format(new Date(month), 'MMM')
                        return (
                          releasesByMonth[formattedMonth] && (
                            <Tab
                              key={formattedMonth}
                              identifier={formattedMonth}
                              kind="link"
                            >
                              {formattedMonth}
                            </Tab>
                          )
                        )
                      })}
                    </TabList>
                  </div>

                  {months.map(month => {
                    const formattedMonth = format(new Date(month), 'MMM')
                    return (
                      releasesByMonth[formattedMonth] && (
                        <TabPanel
                          key={formattedMonth}
                          identifier={formattedMonth}
                        >
                          {reverse(
                            releasesSortedByDate(
                              releasesByMonth[formattedMonth],
                            ),
                          ).map(release => {
                            const title = (
                              <Typography tag="h5">
                                {release.title}{' '}
                                <span>
                                  {format(
                                    new Date(release.publishedDate),
                                    'dd MMMM yyyy',
                                  )}
                                </span>
                              </Typography>
                            )
                            const link = release.externalUrl ? (
                              <a
                                className={styles.releaseTitle}
                                href={release.externalUrl}
                                target="_blank"
                                rel="noreferrer"
                              >
                                {title}
                              </a>
                            ) : (
                              <TransitionLink
                                to={release.slug}
                                className={styles.releaseTitle}
                              >
                                {title}
                              </TransitionLink>
                            )
                            return link
                          })}
                        </TabPanel>
                      )
                    )
                  })}
                </Tabs>
              </div>
            )
          })}
        </div>
      </div>
    </LayoutFullWidth>
  )
}

PressOfficePage.propTypes = {
  data: PropTypes.shape({
    page: PropTypes.shape({
      frontmatter: PropTypes.shape({
        header: headerProps,
      }),
    }),
    pressReleases: PropTypes.arrayOf(PropTypes.any),
  }),
}

export default PressOfficePage

export const pageQuery = graphql`
  query PressOfficePageTemplate {
    page: mdx(frontmatter: { title: { eq: "About Us" } }) {
      frontmatter {
        ...headerFields
      }
    }
    pressReleases: allMdx(
      filter: { fields: { contentType: { eq: "pressReleases" } } }
    ) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            title
            publishedDate
            externalUrl
            featuredImage {
              childCloudinaryMediaImage {
                fixed(width: 100) {
                  aspectRatio
                  height
                  src
                  srcSet
                  width
                }
              }
            }
          }
        }
      }
    }
  }
`
